import * as React from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  useTheme,
} from "@mui/material";

import Box from "@mui/material/Box";
import Stat from "components/stat";
import { decompressBase64 } from "lib/compression";
import _ from "lodash";
import { xirr, XirrInput } from "node-irr";
import { useNavigate, useSearchParams } from "react-router-dom";

type ContributionValue = {
  value: number;
  date: Date;
};

type InputData = {
  v: number;
  c: Array<ContributionValue>;
};

export default function PerformanceResults() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const [actualValue, setActualValue] = React.useState<number>(0);
  const [actualAPY, setActualAPY] = React.useState<number>(0);

  const [idealValue, setIdealValue] = React.useState<number>(0);
  const [idealAPY, setIdealAPY] = React.useState<number>(0);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);

  const [signupOpen, setSignupOpen] = React.useState<boolean>(false);
  const [signupLoading, setSignupLoading] = React.useState<boolean>(false);
  const [signupError, setSignupError] = React.useState<string | null>(null);
  const [signupComplete, setSignupComplete] = React.useState<boolean>(false);
  const handleSignupOpen = () => {
    setSignupOpen(true);
    setSignupError(null);
    setSignupLoading(false);
  };
  const handleSignupClose = () => {
    setSignupOpen(false);
    setSignupError(null);
    setSignupLoading(false);
  };

  React.useEffect(() => {
    let data;
    try {
      data = parseData(params);
    } catch (e) {
      console.error(e);
      setError(true);
      setLoading(false);
      return;
    }

    if (!data) {
      console.log("No data");
      navigate("/performance");
      return;
    }

    const input = data as InputData;
    const val = input.v;
    const contributions = input.c;

    console.log("Contributions", contributions);
    setActualValue(val);

    Promise.all(
      _.map(contributions, (c: ContributionValue) => {
        return fetch(indexDataURL(c?.date as Date))
          .then(jsonResponse)
          .then((d) => {
            const value = c.value as number;
            const price = +d.price;
            return {
              date: c.date,
              price: price,
              value: value,
              units: value / price,
            };
          });
      })
    )
      .then((results) => {
        console.log("Final data", results);

        const totalUnits = _.sum(_.map(results, (r) => r.units));
        const now = new Date();

        return fetch(indexDataURL(now))
          .then(jsonResponse)
          .then((d) => {
            const price = +d.price;
            const value = totalUnits * price;

            const irrDeposits = _.map(contributions, (c: ContributionValue) => {
              return { amount: -c.value, date: c.date } as XirrInput;
            });

            const actualIRR = xirr(
              irrDeposits.concat({
                amount: val,
                date: new Date(),
              } as XirrInput)
            );
            const idealIRR = xirr(
              irrDeposits.concat({
                amount: value,
                date: new Date(),
              } as XirrInput)
            );

            const idealDays = _.min([365, idealIRR.days - 1]) as number;
            const idealAPY = (1.0 + idealIRR.rate) ** idealDays - 1.0;

            const actualDays = _.min([365, actualIRR.days - 1]) as number;
            const actualAPY = (1.0 + actualIRR.rate) ** actualDays - 1.0;

            setIdealValue(value);
            setIdealAPY(idealAPY);
            setActualAPY(actualAPY);

            setLoading(false);
          });
      })
      .catch(() => {});
  }, [params, navigate]);

  const signup = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSignupLoading(true);

    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const email = formJson.email;

    fetch(`https://api.backend.us-e1.prod.wealthmode.co/beta`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        setSignupComplete(true);
      })
      .catch((err) => {
        console.log("Signup error: ", err);
        setSignupError("Could not complete signup, please try again later.");
      })
      .finally(() => {
        setSignupLoading(false);
      });
  };

  const styles: Record<string, React.CSSProperties> = {
    container: {
      maxWidth: "480px",
      margin: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
      gap: "36px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    figures: {
      justifyContent: "center",
      display: "flex",
      gap: "32px",
    },
    figuresControlOption: {
      width: "32px",
      paddingTop: "2px",
    },
    exposition: {
      display: "flex",
      flexDirection: "column",
      gap: "inherit",
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      gap: "inherit",
    },
    p: {
      textAlign: "left",
    },
  };

  return (
    <Box style={styles.container}>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Box>
          Sorry! There was a problem analyzing your data. Please try again
          later.
        </Box>
      ) : (
        <>
          <Box component="h1">
            {Math.abs(1 - idealValue / actualValue) > 0.02
              ? moneyFormatterFull.format(actualValue - idealValue)
              : "Tied!"}
          </Box>

          <Box key="figures" style={styles.figures}>
            <Stat
              title="Your Portfolio"
              figure={moneyFormatter.format(actualValue)}
              subtitle={apyFormatter.format(actualAPY) + " APY"}
              color={
                idealValue > actualValue
                  ? theme.palette.text.primary
                  : theme.palette.primary.main
              }
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <Stat
              title="The Market"
              figure={moneyFormatter.format(idealValue)}
              subtitle={apyFormatter.format(idealAPY) + " APY"}
              color={
                idealValue > actualValue
                  ? theme.palette.primary.main
                  : theme.palette.text.primary
              }
            />
          </Box>

          <Box key="exposition" style={styles.exposition}>
            <Box style={styles.p}>
              Sign up for Wealthmode to learn how to make more money.
            </Box>
          </Box>
        </>
      )}

      <Box key="buttons" style={styles.buttons}>
        <Button variant="contained" onClick={handleSignupOpen}>
          Sign Up
        </Button>
        <Button variant="outlined" onClick={() => navigate("/performance")}>
          Reset
        </Button>
      </Box>

      <Dialog
        open={signupOpen}
        onClose={handleSignupClose}
        PaperProps={{
          component: "form",
          onSubmit: signup,
        }}
      >
        <DialogTitle>Subscribe</DialogTitle>

        {signupComplete ? (
          <>
            <DialogContent>
              <DialogContentText>
                Subscribe to be notified when Wealthmode launches! <br /> <br />
                Thank you!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSignupClose}>Close</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Subscribe to be notified when Wealthmode launches!
              </DialogContentText>
              <TextField
                autoFocus
                required
                error={signupError ? true : false}
                slotProps={{
                  htmlInput: { style: { textAlign: "left" } },
                }}
                margin="dense"
                id="email"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="filled"
                helperText={signupError}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSignupClose}>Cancel</Button>
              <LoadingButton
                loading={signupLoading}
                variant="contained"
                type="submit"
              >
                Subscribe
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
}

const indexDataURL = (date: Date): string => {
  return `https://api.backend.us-e1.prod.wealthmode.co/historical/stocks/SPY/${isoDate(
    date
  )}`;
};

const jsonResponse = (r: Response) => {
  if (!r.ok) {
    throw new Error(
      "Historical data fetch error: " + r.statusText + " - " + r.body
    );
  }
  return r.json();
};

const isoDate = (d: Date): string => {
  return d.toISOString().split("T")[0];
};

const apyFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  currency: "USD",
  maximumSignificantDigits: 3,
  maximumFractionDigits: 0,
});

const moneyFormatterFull = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  signDisplay: "always",
  maximumSignificantDigits: 4,
  maximumFractionDigits: 0,
});

const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumSignificantDigits: 2,
  maximumFractionDigits: 0,
  notation: "compact",
});

function parseData(params: URLSearchParams): boolean | InputData {
  const queryData = params.get("d");
  if (!queryData) {
    return false;
  }

  const decodedData = decompressBase64(queryData);
  if (!decodedData) {
    return false;
  }

  const data = JSON.parse(decodedData);
  if (!data) {
    return false;
  }

  data.c = _.map(data.c, (c) => {
    return {
      value: c.value,
      date: new Date(c.date),
    };
  });

  return data;
}
